import originAxios from 'axios'
import qs from 'qs'
import Vue from 'vue';
import { Toast } from 'vant';
import router from '../router'
Vue.use(Toast);
export default function axios(option) {
    // const toast= new Toast()
    return new Promise((resolve, reject) => {
        // console.log('koten2222', localStorage.getItem("token"))
        // 1.创建axios的实例
        const instance = originAxios.create({
            baseURL: 'http://hcll.hclwkk.com',
            // baseURL: '/apis'
            // timeout: 100,
        });


        // 配置请求和响¸¸应拦截
        instance.interceptors.request.use(config => {

            if (localStorage.getItem('token')) {
                config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
            }
            // console.log('koten33', sessionStorage.getItem("token"))
            // config.headers['Authorization'] = sessionStorage.getItem("token");

            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
                loadingType: 'spinner'
            });
            // console.log('来到了request拦截success中');
            // 1.当发送网络请求时, 在页面中添加一个loading组件, 作为动画

            // 2.某些请求要求用户必须登录, 判断用户是否有token, 如果没有token跳转到login页面

            // 3.对请求的参数进行序列化(看服务器是否需要序列化)
            // config.data = qs.stringify(config.data)
            // console.log(config);

            // 4.等等
            return config
        }, err => {
            // console.log('来到了request拦截failure中');
            return err
        })

        instance.interceptors.response.use(response => {
            // console.log('来到了response拦截success中');
            console.log("状态", response.data.code);
            Toast.clear();
            return response.data
        }, err => {
            Toast.clear()
                // Toast.fail('访问失败');
            if (err && err.response) {
                // console.log('jjjj', err.response)
                switch (err.response.status) {
                    case 401:
                        localStorage.removeItem('token');
                        router.push('/login')
                        console.log('ff', err.response)
                        if (err.response.data) {
                            Toast.fail(err.response.data.message);
                        } else {
                            Toast.fail("请登录");

                        }
                        break;
                    case 403:
                        if (err.response.data) {
                            Toast.fail(err.response.data.message);

                        } else {
                            Toast.fail('权限认证失败');

                        }
                        break;
                    case 404:
                        Toast.fail('数据不存在');
                        break;
                    case 422:
                        var mobile = err.response.data.errors
                        for (var index in mobile) {
                            var value = mobile[index][0]
                            Toast.fail(value);
                        }
                        break;
                    default:
                        Toast.fail('服务器错误');
                        break;
                }
            }
            return err
        })

        // 2.传入对象进行网络请求
        instance(option).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}